import { CommentDocument } from "./comment.model";
import { AdministratorComment } from "./AdministratorComment";
import { UnknownComment } from "./UnknownComment";
import { TrelloComment } from "./TrelloComment";
import { FollowUpComment } from "./FollowUpComment";
import { OfferComment } from "./OfferComment";
import { OfferAcceptedCustomerComment } from "./OfferAcceptedCustomerComment";
import { OfferDeclinedCustomerComment } from "./customer/OfferDeclinedCustomerComment";
import { OfferExpiredCustomerComment } from "./OfferExpiredCustomerComment";
import { OrderStalledCustomerComment } from "./OrderStalledCustomerComment";
import { NewOrderCustomerComment } from "./NewOrderCustomerComment";
import { NewRegistrationComment } from "./shared/NewRegistrationComment";
import { MatchCancelledComment } from "./MatchCancelledComment";
import { MessageSentCustomerComment } from "./customer/MessageSentCustomerComment";
import { CustomerDeletedComment } from "./customer/CustomerDeletedComment";
import { ChangeTimeOfTaskComment } from "./shared/ChangeTimeOfTaskComment";
import { InvoiceCreditComment } from "./InvoiceCreditComment";
import { CustomerInvoiceUpdateComment } from "./customer/CustomerInvoiceUpdateComment";
import { OfferAcceptedGardenerComment } from "./OfferAcceptedGardenerComment";
import { OfferDeclinedGardenerComment } from "./gardener/OfferDeclinedGardenerComment";
import { GardenerInvoiceUpdateComment } from "./gardener/GardenerInvoiceUpdateComment";
import { GardenerPausedComment } from "./gardener/GardenerPausedComment";
import { GardenerUnpausedComment } from "./gardener/GardenerUnpausedComment";
import { UpdatedComment } from "./shared/UpdatedComment";
import { MessageSentGardenerComment } from "./gardener/MessageSentGardenerComment";
import { GardenerAgreementAcceptedComment } from "./GardenerAgreementAcceptedComment";
import { GardenerApplicationApprovedComment } from "./GardenerApplicationApprovedComment";
import { GardenerApplicationReceivedComment } from "./GardenerApplicationReceivedComment";
import { OfferExpiredGardenerComment } from "./OfferExpiredGardenerComment";
import { MatchCreatedComment } from "./shared/MatchCreatedComment";
import { AppointmentRegisteredComment } from "./shared/AppointmentRegisteredComment";
import { AppointmentChangedComment } from "./shared/AppointmentChangedComment";
import { AppointmentCancelledComment } from "./shared/AppointmentCancelledComment";
import { MatchRemovedComment } from "./shared/MatchRemovedComment";
import { MatchOfferRetractedComment } from "./shared/MatchOfferRetractedComment";
import { NewVisitComment } from "./shared/NewVisitComment";
import { CloseTaskComment } from "./shared/CloseTaskComment";
import { MatchPostponementComment } from "./shared/MatchPostponementComment";
import { ContactPostponedComment } from "./shared/ContactPostponedComment";
import { CheckRegistrationClarificationTaskActionComment } from "./shared/CheckRegistrationClarificationTaskActionComment";
import { CustomerReactivatedComment } from "./customer/CustomerReactivatedComment";
import { AutomaticPaymentSubscriptionComment } from "./shared/AutomaticPaymentSubscriptionComment";
import { AutomaticPaymentFailedComment } from "./shared/AutomaticPaymentFailedComment";
import { GardenerTerminatedComment } from "./shared/GardenerTerminatedComment";
import { ProcessProtectionActivationComment } from "./gardener/ProcessProtectionActivationComment";
import { PaymentCollectionComment } from "./PaymentCollectionComment";
import { ComplaintComment } from "../complaint/ComplaintComment";
import { OnboardingComment } from "../onboarding/OnboardingComment";
import { NewGardeningTaskComment } from "./customer/NewGardeningTaskComment";
import { RepeatOrderComment } from "./customer/RepeatOrderComment";
import { GardeningTaskClosedComment } from "./customer/GardeningTaskClosedComment";
import { InformationInputComment } from "./InformationInputComment";
import { InformationOutputComment } from "./InformationOutputComment";
import { InvoiceReissueComment } from "./InvoiceReissueComment";
import { GardeningTaskUpdatedComment } from "./shared/GardeningTaskUpdatedComment";
import { EvaluateGardenerComment } from "../evaluate-gardener/EvaluateGardenerComment";
import { InvoiceResendComment } from "./InvoiceResendComment";
import { GuideCompletedComment } from "./GuideCompletedComment";
import { GuidesUnlockedComment } from "./GuidesUnlockedComment";

interface ContextAwareCommentProps {
    context: "customer" | "gardener";
    comment: CommentDocument;
}

export const ContextAwareComment = (props: ContextAwareCommentProps) => {
    const { context, comment } = props;

    switch ( comment.type ) {
        case "appointment-registered": return <AppointmentRegisteredComment context={context} comment={comment} />;
        case "appointment-changed": return <AppointmentChangedComment context={context} comment={comment} />;
        case "appointment-cancelled": return <AppointmentCancelledComment context={context} comment={comment} />;
        case "evaluate-gardener": return <EvaluateGardenerComment comment={comment} />;
        case "guides unlocked": return <GuidesUnlockedComment comment={comment} />;
        case "guide completed": return <GuideCompletedComment comment={comment} />;
        case "offer": return <OfferComment comment={comment} />;
        case "repeat-order": return <RepeatOrderComment comment={comment} />;
        case "invoice-credit": return <InvoiceCreditComment context={context} comment={comment} />;
        case "invoice-reissue": return <InvoiceReissueComment comment={comment} />;
        case "invoice-resend": return <InvoiceResendComment comment={comment} />;
        case "invoice-reminder-1": return <PaymentCollectionComment comment={comment} />;
        case "invoice-reminder-2": return <PaymentCollectionComment comment={comment} />;
        case "did-not-reach-customer-before-invoice-reminder-fee-1": return <PaymentCollectionComment comment={comment} />;
        case "did-reach-customer-before-invoice-reminder-fee-1": return <PaymentCollectionComment comment={comment} />;
        case "did-send-letter-with-invoice-reminder-fee-1": return <PaymentCollectionComment comment={comment} />;
        case "did-send-letter-with-invoice-reminder-fee-2": return <PaymentCollectionComment comment={comment} />;
        case "did-send-letter-with-invoice-reminder-fee-3": return <PaymentCollectionComment comment={comment} />;
        case "invoice-reminder-fee-1": return <PaymentCollectionComment comment={comment} />;
        case "invoice-reminder-fee-2": return <PaymentCollectionComment comment={comment} />;
        case "invoice-reminder-fee-3": return <PaymentCollectionComment comment={comment} />;
        case "did-not-reach-customer-before-invoice-debt-collection": return <PaymentCollectionComment comment={comment} />;
        case "did-reach-customer-before-invoice-debt-collection": return <PaymentCollectionComment comment={comment} />;
        case "gardener-updated": return <UpdatedComment context="gardener" comment={comment} />;
        case "customer-updated": return <UpdatedComment context="customer" comment={comment} />;
        case "onboarding": return <OnboardingComment comment={comment} />;
        case "order-updated": return <UpdatedComment context="customer" comment={comment} />;
        case "match-created": return <MatchCreatedComment context={context} comment={comment} />;
        case "match-offer-retracted": return <MatchOfferRetractedComment comment={comment} />;
        case "match-removed": return <MatchRemovedComment comment={comment} />;
        case "new-registration": return <NewRegistrationComment comment={comment} />;
        case "new-inspection": return <NewVisitComment comment={comment} />;
        case "task-closed": return <CloseTaskComment context={context} comment={comment} />;
        case "match-postponement": return <MatchPostponementComment comment={comment} />;
        case "new-gardening-task": return <NewGardeningTaskComment comment={comment} />;
        case "gardening-task-updated": return <GardeningTaskUpdatedComment comment={comment} />;
        case "gardening-task-closed": return <GardeningTaskClosedComment comment={comment} />;
        case "contact-postponed": return <ContactPostponedComment context={context} comment={comment} />;
        case "check-registration-clarification-task-action": return <CheckRegistrationClarificationTaskActionComment context={context} comment={comment} />;
        case "automatic-payment-subscription": return <AutomaticPaymentSubscriptionComment comment={comment} />;
        case "automatic-payment-failed": return <AutomaticPaymentFailedComment comment={comment} />;
        case "gardener-terminated": return <GardenerTerminatedComment comment={comment} />;
        case "match-cancelled": return <MatchCancelledComment comment={comment} />;
        case "process-protection-activation": return <ProcessProtectionActivationComment comment={comment} />;
        case "complaint": return <ComplaintComment comment={comment} />;
        case "information-input": return <InformationInputComment comment={comment} />;
        case "information-output": return <InformationOutputComment comment={comment} />;
    }

    if ( context === "customer" ) {
        switch ( comment.type ) {
            case "customer-administrator": return <AdministratorComment comment={comment} />;
            case "customer-deleted": return <CustomerDeletedComment context="customer" comment={comment} />;
            case "customer-reactivated": return <CustomerReactivatedComment comment={comment} />;
            case "trello-customer": return <TrelloComment comment={comment} />;
            case "follow-up-customer": return <FollowUpComment comment={comment} />;
            case "offer-accepted": return <OfferAcceptedCustomerComment comment={comment} />;
            case "offer-declined": return <OfferDeclinedCustomerComment comment={comment} />;
            case "offer-expired": return <OfferExpiredCustomerComment comment={comment} />;
            case "new-order": return <NewOrderCustomerComment comment={comment} />;
            case "order-stalled": return <OrderStalledCustomerComment comment={comment} />;
            case "message-sent": return <MessageSentCustomerComment comment={comment} />;
            case "change-time-of-task": return <ChangeTimeOfTaskComment comment={comment} />;
            case "invoice-update": return <CustomerInvoiceUpdateComment comment={comment} />;
        }
    }

    if ( context === "gardener" ) {
        switch ( comment.type ) {
            case "gardener-administrator": return <AdministratorComment comment={comment} />;
            case "trello": return <TrelloComment comment={comment} />;
            case "follow-up-gardener": return <FollowUpComment comment={comment} />;
            case "offer-accepted": return <OfferAcceptedGardenerComment comment={comment} />;
            case "offer-declined": return <OfferDeclinedGardenerComment comment={comment} />;
            case "offer-expired": return <OfferExpiredGardenerComment comment={comment} />;
            case "message-sent": return <MessageSentGardenerComment comment={comment} />;
            case "gardener-application-received": return <GardenerApplicationReceivedComment comment={comment} />;
            case "gardener-agreement-accepted": return <GardenerAgreementAcceptedComment comment={comment} />;
            case "gardener-application-approved": return <GardenerApplicationApprovedComment comment={comment} />;
            case "change-time-of-task": return <ChangeTimeOfTaskComment comment={comment} />;
            case "invoice-update": return <GardenerInvoiceUpdateComment comment={comment} />;
            case "gardener-paused": return <GardenerPausedComment comment={comment} />;
            case "gardener-unpaused": return <GardenerUnpausedComment comment={comment} />;
        }
    }

    return <UnknownComment key={comment.id} comment={comment} />;
};
